import { useIntl } from "react-intl";
import { IconButton, Tooltip } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import FormatListNumberedOutlinedIcon from "@material-ui/icons/FormatListNumberedOutlined";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";

interface Icons {
  seeIcon?: boolean;
  editIcon?: boolean;
  deleteIcon?: boolean;
  assignIcon?: boolean;
  chapterIcon?: boolean;
  questionIcon?: boolean;
  photoIcon?: boolean;
  onSee?: VoidFunction;
  onEdit?: VoidFunction;
  onDelete?: VoidFunction;
  onAssign?: VoidFunction;
  onChapter?: VoidFunction;
  onQuestion?: VoidFunction;
  onPhoto?: VoidFunction;
}

export const CustomizedIcons = (props: Icons) => {
  const {
    seeIcon,
    editIcon,
    deleteIcon,
    assignIcon,
    chapterIcon,
    questionIcon,
    photoIcon,
    onPhoto,
    onSee,
    onEdit,
    onDelete,
    onAssign,
    onChapter,
    onQuestion,
  } = props;
  const intl = useIntl();

  return (
    <>
      {photoIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "DataProcessing" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onPhoto}>
            {" "}
            <AddAPhotoIcon />{" "}
          </IconButton>
        </Tooltip>
      )}
      {seeIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "See" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onSee}>
            {" "}
            <VisibilityOutlinedIcon />{" "}
          </IconButton>
        </Tooltip>
      )}
      {editIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "Edit" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onEdit}>
            {" "}
            <EditOutlinedIcon />{" "}
          </IconButton>
        </Tooltip>
      )}
      {deleteIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "Delete" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onDelete}>
            {" "}
            <DeleteOutlineOutlinedIcon />{" "}
          </IconButton>
        </Tooltip>
      )}
      {assignIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "AssignSurveys" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onAssign}>
            {" "}
            <DescriptionOutlinedIcon />{" "}
          </IconButton>
        </Tooltip>
      )}
      {chapterIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "AddChapters" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onChapter}>
            {" "}
            <FormatListNumberedOutlinedIcon />{" "}
          </IconButton>
        </Tooltip>
      )}
      {questionIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "AddQuestions" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onQuestion}>
            {" "}
            <HelpOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};
