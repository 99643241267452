import { Charts } from "../../../components/admin/graphics/Charts";
import { Grid } from "@material-ui/core";
import { SurveyFilter } from "../../../components/admin/graphics/SurveyFilter";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useState } from "react";
import { ISurveyFilterValues } from "../../../interfaces/Survey";

export const GraphicsScreen = () => {
  const { answers, questionList } = useSelector(
    (state: AppState) => state.survey
  );
  const [dataFilter, setDataFilter] = useState<ISurveyFilterValues | null>(
    null
  );

  const getQuestionDetail = () => {
    if (!dataFilter) {
      return "";
    }
    const questionDetail = questionList.find(
      (question) => question.id === dataFilter.questionId
    );
    return questionDetail ? questionDetail.question : "";
  };
  const callbackFilter = (formValues: any) => {
    setDataFilter(formValues);
  };

  const buildChatData = () => {
    if (!dataFilter) {
      return [];
    }
    //LISTADO DE OPCIONES DE LA PREGUNTA
    const questionDetail = questionList.find(
      (question) => question.id === dataFilter.questionId
    );
    if (!questionDetail) return [];
    const { options } = questionDetail;
    const optionsValues = options?.map((op) => op.value);
    const chartData: any = [];
    //HACER COUNT DE CUANTAS PERSONAS RESPONDEN A CADA OPCION

    optionsValues?.forEach((val) => {
      const optionDetail = options?.find((op) => op.value === val);
      //Calcular total de respuestas por valor
      const total = calculateCountAswer(val);

      chartData.push({
        name: optionDetail?.label,
        y: total,
      });
    });
    return chartData;
  };

  const calculateCountAswer = (answerValue: any) => {
    const totalAnswers = answers.filter((ans) => {
      if (Array.isArray(ans.respuesta)) {
        return ans.respuesta.find((res: any) => res.value === answerValue);
      }

      return ans.respuesta?.value === answerValue;
    });

    return totalAnswers.length;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SurveyFilter callback={callbackFilter} />
        </Grid>
        {answers.length > 0 && (
          <Grid item xs={12}>
            <Charts data={buildChatData()} title={getQuestionDetail()} />
          </Grid>
        )}
      </Grid>
    </>
  );
};
