import React, { FC, useEffect, useRef } from "react";
import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Paper,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  setAnswersList,
  setChapterList,
  setQuestionList,
  setSurveyList,
  startLoadAnswerList,
  startLoadChapterList,
  startLoadQuestionList,
  startLoadSurveyList,
} from "../../../redux/actions/surveysActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { ISurverFilterProps } from "../../../interfaces/Survey";

// Validación con Yup
const SurveyFilterSchema = Yup.object().shape({
  surveyId: Yup.string().required("La encuesta es requerida"),
  chapterId: Yup.string().required("El capítulo es requerido"),
  questionId: Yup.string().required("La pregunta es requerida"),
});

export const SurveyFilter: FC<ISurverFilterProps> = ({ callback }) => {
  const { surveyList, chapterList, questionList } = useSelector(
    (state: AppState) => state.survey
  );

  const formikRef = useRef(null);
  const dispatch = useDispatch();

  const initialValues = {
    surveyId: "",
    chapterId: "",
    questionId: "",
  };

  useEffect(() => {
    const fetchSurveys = async () => {
      await dispatch(startLoadSurveyList());
    };
    fetchSurveys();
    resetData();
    return () => {
      dispatch(setSurveyList([]));
      //resetData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Simulación de obtención de datos
  const fetchChapters = async (surveyId: string) => {
    dispatch(startLoadChapterList(surveyId));
  };

  const fetchQuestions = async (chapterId: string) => {
    if (!formikRef.current) {
      return;
    }
    const { values } = formikRef.current as any;
    dispatch(startLoadQuestionList(values.surveyId, chapterId));
  };
  const resetChapterList = () => {
    dispatch(setChapterList([]));
  };
  const resetQuestionList = () => {
    dispatch(setQuestionList([]));
  };

  const getAnswerToChart = async (formValues: typeof initialValues) => {
    const { questionId, surveyId, chapterId } = formValues;
    const detailQuestion = questionList.find(
      (question) => question.id === questionId
    );
    if (!detailQuestion) {
      return false;
    }
    callback(formValues);
    await dispatch(
      startLoadAnswerList(
        surveyId,
        chapterId,
        detailQuestion?.directedTo,
        questionId
      )
    );
  };

  const resetData = () => {
    dispatch(setChapterList([]));
    dispatch(setQuestionList([]));
    dispatch(setAnswersList([]));
  };

  return (
    <Paper>
      <Box p={2}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={SurveyFilterSchema}
          validateOnChange
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await getAnswerToChart(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            resetForm,
            setFieldValue,
            isValid,
            isSubmitting,
          }) => (
            <Form>
              <Grid container spacing={2}>
                {/* Campo de ID de Encuesta */}
                <Grid item xs={4}>
                  <FormControl
                    fullWidth
                    error={touched.surveyId && Boolean(errors.surveyId)}
                    size="small"
                    variant="outlined"
                  >
                    <InputLabel id="surveyId-label">Encuesta</InputLabel>
                    <Select
                      name="surveyId"
                      labelId="surveyId-label"
                      value={values.surveyId}
                      onChange={(e) => {
                        handleChange(e);

                        fetchChapters(`${e.target.value}`);
                        setFieldValue("chapterId", "");
                        resetChapterList();
                        resetQuestionList();
                      }}
                      label="Encuesta"
                    >
                      {surveyList.map((survey) => (
                        <MenuItem key={survey.id} value={survey.id}>
                          {survey.titulo}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.surveyId && Boolean(errors.surveyId) && (
                      <div style={{ color: "red" }}>{errors.surveyId}</div>
                    )}
                  </FormControl>
                </Grid>

                {/* Campo de Selección de Capítulo */}
                <Grid item xs={4}>
                  <FormControl
                    fullWidth
                    error={touched.chapterId && Boolean(errors.chapterId)}
                    size="small"
                    variant="outlined"
                  >
                    <InputLabel id="chapterId-label">Capítulo</InputLabel>
                    <Select
                      name="chapterId"
                      labelId="chapterId-label"
                      value={values.chapterId}
                      onChange={(e) => {
                        handleChange(e);
                        fetchQuestions(`${e.target.value}`);
                        setFieldValue("questionId", "");
                        resetQuestionList();
                      }}
                      label="Capítulo"
                    >
                      {chapterList.map((chapter) => (
                        <MenuItem key={chapter.id} value={chapter.id}>
                          {chapter.titulo}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.chapterId && Boolean(errors.chapterId) && (
                      <div style={{ color: "red" }}>{errors.chapterId}</div>
                    )}
                  </FormControl>
                </Grid>

                {/* Campo de Selección de Pregunta */}
                <Grid item xs={4}>
                  <FormControl
                    fullWidth
                    error={touched.questionId && Boolean(errors.questionId)}
                    size="small"
                    variant="outlined"
                  >
                    <InputLabel id="questionId-label">Pregunta</InputLabel>
                    <Select
                      name="questionId"
                      labelId="questionId-label"
                      value={values.questionId}
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(setAnswersList([]));
                      }}
                      label="Pregunta"
                    >
                      {questionList.map((question) => (
                        <MenuItem key={question.id} value={question.id}>
                          {question.question}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.questionId && Boolean(errors.questionId) && (
                      <div style={{ color: "red" }}>{errors.questionId}</div>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              {/* Botones de acciones */}
              <Grid
                container
                justifyContent="flex-end"
                spacing={2}
                style={{ marginTop: "16px" }}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      resetForm();
                      resetData();
                      callback(initialValues);
                    }}
                  >
                    Limpiar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!isValid || isSubmitting}
                  >
                    {isSubmitting ? (
                      <CircularProgress color="inherit" size="1.5rem" />
                    ) : (
                      "Filtrar"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Paper>
  );
};
