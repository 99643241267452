import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from "highcharts-react-official";
import { FC } from "react";
import { IChartProps } from "../../../interfaces/Charts";

export const Charts: FC<IChartProps> = ({ title, data }) => {
  const categories = data.map((item) => item.name);
  const options: Highcharts.Options = {
    chart: {
      type: "column",
    },
    title: {
      text: title,
    },
    xAxis: {
      categories,
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Número de Respuestas",
        align: "high",
      },
      labels: {
        overflow: "justify",
      },
    },
    tooltip: {
      valueSuffix: " respuestas",
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [
      {
        name: "Respuestas",
        colorByPoint: true,
        data: data,
        showInLegend: false,
      } as any,
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
